
$('.header__search__img').click(function(){
   	$(".header__search__hidden").slideToggle();
});

$('.tab__title').click(function(){
   	var activeTab = $(this).attr('data-href');
   	$(".tab__title").removeClass("tab__title--active");
   	$(".tab__content").removeClass("tab__content--active");
   	$(this).addClass("tab__title--active");
   	$(activeTab).addClass("tab__content--active");
});




 var slideout = new Slideout({
    'panel': document.getElementById('page'),
    'menu': document.getElementById('menu__mobile'),
    'padding': 256,
    'tolerance': 70,
    'touch' : false
  });

$('#hamburger').on('click', function() {
    slideout.toggle();
});


  $('#hamburger').click(function(){
    $(this).toggleClass('is-active');
  });
